import axios from "axios";
import qs from "qs";
import {
	Loading
} from 'element-ui';
const install = Vue => {
	Vue.prototype.apis = {
		// 接口
		// banner:'api/Index/banner_list'

	};
	//声明一个loading空变量
	let loadingIsShow = null;
	// 新创建一个axios实例，并进行基础配置
	var instance = axios.create({
		// 线上配置  baseURL: "https://server.ccyilianda.com",
		baseURL: "https://server.lyfyjk.com:8787",// lr本地
	
		timeout: 7000,
		headers: {
			// "Content-Type": "application/x-www-form-urlencoded",
			'content-type': 'application/json',
		}
	});

	// 添加请求拦截器
	instance.interceptors.request.use(config => {
		// 再次设置tkoen或者添加loading等请求前的操作
		// 添加一个loading
		loadingIsShow = Loading.service({
			lock: true,
			text: "Loading",
			spinner: "el-icon-loading",
			background: "rgba(0, 0, 0, 0.7)"
		});
		return config;
	});

	// // 添加xi响应拦截器
	instance.interceptors.response.use(
		response => {
			//响应数据后做点什么
			// 请求成功关闭loading
			// console.log("请求成功");
			loadingIsShow.close();
			return response;
		},
		error => {
			// 对响应错误做点什么
			return Promise.reject(error);
		}
	);

	Vue.prototype.api = function(url, params = {}) {
		const that = this;
		var userId= localStorage.getItem('userId');
		if(userId!=null&&userId!=undefined&&userId!=''){
			params.userId=Number(userId);
		}else{	
			if(this.$route.matched[0].path!='/log'){
				that.$router.replace({name:'log'})
			}
		}
		return new Promise((resolve, reject) => {
			instance
				.post(url, params)
				.then(res => {
					if (res.data.code != 200) {
						 this.$message(res.data.msg);
					} 
					// else if (res.data.status == -200) {
					// 	localStorage.removeItem("token");
					// 	that.$router.replace({
					// 		name: 'log'
					// 	})
					// } 
					resolve(res.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	};
};
export default install;
